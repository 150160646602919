import { createApp } from "vue";
import App from "./App.vue";
import rooter from "../src/router/index";
import install from "./install";
import { VueSvgIconPlugin } from "@yzfe/vue3-svgicon";
import "@yzfe/svgicon/lib/svgicon.css";
import "./registerServiceWorker";
import i18n from "./i18n";
import vuex from "./store";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

createApp(App)
  .use(i18n)
  .use(Datepicker)
  .use(vuex)
  .use(VueSvgIconPlugin, { tagName: "icon" })
  .use(install)
  .use(rooter)
  .mount("#app");
