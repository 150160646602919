/**
 *PROJECT Trip
 * Created by WebStorm.
 * Author Khaliq ALI<hello@kgeek.me>)
 * Date 30/12/2021
 * TIME 17:52
 **/

import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

const vuex = createStore({
  plugins: [createPersistedState()],
  state: {},
  mutations: {},
  actions: {},
  getters: {},
});

export default vuex;
